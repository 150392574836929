@charset "UTF-8";

#ahj-footer {
	/* position: absolute; /*
	bottom: 0;
	width: 100%;
	height: 60px;  /* set the fixed height of the footer here */
	line-height: 60px; /* vertically center the text here */
	background-color: #f5f5f5;
	text-align: center;
}